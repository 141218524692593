import { FormGroup, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { RecordingMediaType } from '../../types'
import { GenericIcon, HeadsetIcon } from '../utils/Icons'

interface RecordingTypeChooserProps {
    recordingTypes: RecordingMediaType[]
    selected: RecordingMediaType
    setSelected: (recordingType: RecordingMediaType) => void
    enabled: boolean
}

export const RecordingTypeChooser = ({ recordingTypes, selected, setSelected, enabled }: RecordingTypeChooserProps) => {
    const { t } = useTranslation()

    return (
        <form className="passage-recording-type-chooser-form">
            <FormGroup controlId="recording-type-chooser">
                {recordingTypes.map((recordingType) => (
                    <Form.Check
                        type="radio"
                        inline
                        id={recordingType}
                        key={recordingType}
                        onChange={() => {
                            setSelected(recordingType)
                        }}
                        checked={selected === recordingType}
                        label={
                            <div>
                                {t(recordingType)}{' '}
                                {recordingType === 'Audio' ? (
                                    <HeadsetIcon
                                        className="passage-recording-type-headset-icon"
                                        tooltip={t(recordingType)}
                                        enabled={enabled}
                                    />
                                ) : (
                                    <GenericIcon
                                        className="passage-recording-type-video-icon"
                                        iconName="fa-video"
                                        tooltip={t(recordingType)}
                                        enabled={enabled}
                                    />
                                )}
                            </div>
                        }
                        disabled={enabled === false}
                    />
                ))}
            </FormGroup>
        </form>
    )
}
