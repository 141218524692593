import { IDateFormatter } from './DateUtilities'
import { getTimeStamp } from '../components/utils/Helpers'

interface RecordingTimestampProps {
    className: string
    dateBy: string
    name: string
    dateFormatter: IDateFormatter
}

export const VisibleTimestamp = ({ className, dateBy, name, dateFormatter }: RecordingTimestampProps) => {
    return <div className={className}>{getTimeStamp(name, dateBy, dateFormatter)}</div>
}
