/* eslint-disable react/no-array-index-key */

import React, { FC, useEffect, useRef, useState } from 'react'

import { TFunction } from 'i18next'
import { observer /* inject */ } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import { GlossReference } from './ProjectReferenceVideoPlayer'
import { Project } from '../../models3/Project'
import { ProjectReference } from '../../models3/ProjectReferences'
import { ProjectTerm } from '../../models3/ProjectTerm'
import { Root } from '../../models3/Root'
import { SignVideo } from '../../models3/SignVideo'
import { LexMeaning, DefinitionLink, MarbleLemma, MarbleLemmas, getTermSenseName } from '../../resources/Lemmas'
import { RefRange, refRangesToDisplayParts, refToBookId } from '../../resources/RefRange'
import { convertReferenceStrings } from '../../resources/Versifications'
import {
    CancelButton,
    HollowStarButton,
    KeyTermButton,
    OKButton,
    PencilButton,
    PlayButton,
    RecordButton,
    StarButton,
    TrashButton
} from '../utils/Buttons'

import './EnhancedResources.css'

// Show the definition and references for the currently selected Biblical Term

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ERLexMeaningView')

// Find all glosses in this section of verses
function getGlossReferencesThatOverlapProjectReference(projectReference: ProjectReference, project: Project) {
    const glossReferences = []

    const { passageVideo } = projectReference
    const portion = project.portions.find((_portion) => passageVideo._id.startsWith(_portion._id))
    const passage = portion?.passages.find((_passage) => passageVideo._id.startsWith(_passage._id))

    if (!passage) {
        return []
    }

    const markers = passageVideo.getVisibleBiblicalTermMarkers(passage)
    for (const marker of markers) {
        if (projectReference.startingTime <= marker.time && marker.time <= projectReference.endingTime) {
            glossReferences.push(new GlossReference(passage, marker, passageVideo))
        }
    }

    return glossReferences
}

type ProjectTermNotesTextEditorProps = {
    text: string
    save: (text: string) => void
    closeEditor: () => void
}

const ProjectTermNotesTextEditor = observer(({ text, save, closeEditor }: ProjectTermNotesTextEditorProps) => {
    const { t } = useTranslation()
    const [value, setValue] = useState(text)
    return (
        <>
            <textarea autoFocus value={value} className="er-notes-editor" onChange={(e) => setValue(e.target.value)} />
            <OKButton
                enabled
                onClick={() => {
                    save(value)
                    closeEditor()
                }}
                buttonClassName=""
                className="er-glosses-ok-button"
                tooltip={t('Save')}
            />
            <CancelButton
                enabled
                onClick={() => closeEditor()}
                className="er-glosses-cancel-button"
                tooltip={t('Cancel')}
            />
        </>
    )
})

type ProjectTermNotesProps = {
    rt: Root
    notes: string
    save: (notes: string) => void
    t: TFunction
}

const ProjectTermNotes = observer(({ rt, notes, save, t }: ProjectTermNotesProps) => {
    const [editing, setEditing] = useState(false)
    return (
        <div className="er-notes">
            <span className="er-label">{t('biblicalTermNotes')}</span>
            {editing && (
                <ProjectTermNotesTextEditor
                    text={notes}
                    save={(value) => save(value)}
                    closeEditor={() => setEditing(false)}
                />
            )}
            {!editing && <textarea value={notes} className="er-notes-editor" readOnly />}
            {!editing && (
                <PencilButton
                    enabled={!editing && rt.iAmInterpreter}
                    onClick={() => setEditing(!editing)}
                    className="er-project-glosses-edit-button"
                    tooltip={t('Edit')}
                />
            )}
        </div>
    )
})

type TargetGlossesProps = {
    projectTerm?: ProjectTerm
}

const TargetGlosses = observer(({ projectTerm }: TargetGlossesProps) => {
    const { t } = useTranslation()
    return (
        <div className="er-glosses">
            <span className="er-label">{t('targetGlossesHeader')}</span>
            <textarea
                value={projectTerm ? projectTerm.glosses.map((gloss) => gloss.text).join('; ') : ''}
                className="er-gloss-text-editor"
                readOnly
            />
        </div>
    )
})

type IERDefinitionLinkView = {
    definitionLink: DefinitionLink
    setTermId: (resource: string) => void
}

const ERDefinitionLinkView: FC<IERDefinitionLinkView> = ({ definitionLink, setTermId }) => {
    const { text, link } = definitionLink
    if (!link) return <span>{text}</span>
    return (
        <span
            className="er-definition-link"
            onClick={() => {
                setTermId(link)
            }}
        >
            {text}
        </span>
    )
}

type ERReferenceLinkProps = {
    part: string
    projectReferences: ProjectReference[]
    setProjectReference: (projectReference: ProjectReference | null) => void
    rt: Root
    projectTerm?: ProjectTerm
}

const ERReferenceLink = observer(
    ({ part, projectReferences, setProjectReference, rt, projectTerm }: ERReferenceLinkProps) => {
        const pieces = [part]
        while (pieces.length < projectReferences.length) {
            pieces.push(` (${pieces.length + 1})`)
        }

        return (
            <span className="er-reference">
                {pieces.map((piece, i) => {
                    const projectReference = projectReferences[i]

                    // Find all places where this term has been glossed in this section of verses
                    let glossReferences: GlossReference[] = getGlossReferencesThatOverlapProjectReference(
                        projectReference,
                        rt.project
                    )
                    glossReferences = glossReferences.filter((reference) =>
                        projectTerm?.glosses.find((gloss) => gloss._id === reference.gloss.targetGlossId)
                    )

                    return (
                        <React.Fragment key={i}>
                            <span className="er-reference-link er-reference-link-enabled">{piece}</span>
                            {glossReferences.length ? (
                                <KeyTermButton
                                    enabled
                                    buttonClassName="er-play-gloss-button__button"
                                    className="er-play-gloss-button fa-fw"
                                    tooltip={projectReference.info(rt).tooltip}
                                    onClick={() => {
                                        log(`setProjectReference $${JSON.stringify(projectReference.dbg())}`)
                                        setProjectReference(projectReference)
                                    }}
                                />
                            ) : (
                                <PlayButton
                                    enabled
                                    onClick={() => {
                                        log(`setProjectReference $${JSON.stringify(projectReference.dbg())}`)
                                        setProjectReference(projectReference)
                                    }}
                                    className="er-play-verse-button"
                                    tooltip={projectReference.info(rt).tooltip}
                                    selectionPresent={false}
                                />
                            )}
                        </React.Fragment>
                    )
                })}
            </span>
        )
    }
)

type IERReference = {
    rt: Root
    part: string
    index: number
    refRange: RefRange
    setProjectReference: (projectReference: ProjectReference | null) => void
    projectTerm?: ProjectTerm
}

const ERReference: FC<IERReference> = ({ rt, part, index, refRange, setProjectReference, projectTerm }) => {
    const projectReferences = rt.projectReferences.get(refRange)
    // log('projectReferences', JSON.stringify(refRange),
    //     JSON.stringify(projectReferences.map(pr => pr.dbg()) , null, 4))

    if (projectReferences.length === 0)
        return (
            <span className="er-reference er-reference-link" key={index}>
                {part}
            </span>
        )

    return (
        <ERReferenceLink
            part={part}
            projectReferences={projectReferences}
            setProjectReference={setProjectReference}
            rt={rt}
            projectTerm={projectTerm}
        />
    )
}

type IERReferences = {
    rt: Root
    references: string[]
    setProjectReference: (projectReference: ProjectReference | null) => void
    projectTerm?: ProjectTerm
}

const ERReferences: FC<IERReferences> = ({ rt, references, setProjectReference, projectTerm }) => {
    const newReferences = convertReferenceStrings(references, 'Original', rt.project.versification)
    const refRanges = RefRange.refsToRefRanges(newReferences)
    const displayParts = refRangesToDisplayParts(refRanges, rt.project)

    const newBook = (i: number) => refToBookId(refRanges[i - 1].startRef) !== refToBookId(refRanges[i].startRef)

    return (
        <div className="er-references">
            {displayParts.map((part, index) => (
                <span key={index}>
                    {index > 0 && newBook(index) ? <br /> : index > 0 ? '; ' : ''}
                    <ERReference
                        rt={rt}
                        index={index}
                        refRange={refRanges[index]}
                        setProjectReference={setProjectReference}
                        projectTerm={projectTerm}
                        part={part}
                    />
                </span>
            ))}
        </div>
    )
}

type IERMeaningView = {
    rt: Root
    meaning: LexMeaning
    setProjectReference: (projectReference: ProjectReference | null) => void
    setSignPlaying: (signVideo: SignVideo) => void
    termId: string // For example, "SDBG:γένεσις:000001"
    setTermId: (resource: string) => void
    index: number
    startRecording: (term: ProjectTerm) => void
}

const ERMeaningView: FC<IERMeaningView> = observer(
    ({ rt, meaning, setProjectReference, termId, setTermId, index, startRecording, setSignPlaying }) => {
        const { t, i18n } = useTranslation()
        const { language } = i18n
        const [isActiveSense, setIsActiveSense] = useState(false)
        const meaningRef = useRef<HTMLDivElement>(null)

        useEffect(() => {
            if (isActiveSense) {
                meaningRef.current?.scrollIntoView()
            }
        }, [isActiveSense])

        useEffect(() => {
            const termIndex = LexMeaning.getMeaningNumberFromLexicalLink(termId) - 1
            setIsActiveSense(termIndex === index)
        }, [termId, index])

        const projectTerm = rt.project.getProjectTerm(meaning.lexicalLink)

        const saveProjectTermIfDoesNotExist = async () => {
            if (projectTerm) {
                return projectTerm
            }

            const newTerm = rt.project.createProjectTerm(meaning.lexicalLink)
            return rt.project.addProjectTerm(newTerm)
        }

        const setNotes = (notes: string) => {
            if (projectTerm) {
                projectTerm.setNotes(notes)
                return
            }
            const newTerm = rt.project.createProjectTerm(meaning.lexicalLink)
            newTerm.notes = notes
            rt.project.addProjectTerm(newTerm)
        }

        const toggleIsKeyTerm = () => {
            if (projectTerm) {
                projectTerm.setIsKeyTerm(!projectTerm.isKeyTerm)
                return
            }
            const newTerm = rt.project.createProjectTerm(meaning.lexicalLink)
            newTerm.isKeyTerm = !newTerm.isKeyTerm
            rt.project.addProjectTerm(newTerm)
        }

        const starEnabled = rt.iAmInterpreter
        const recordEnabled = rt.iAmInterpreter
        const deleteEnabled = rt.iAmInterpreter && projectTerm && projectTerm.renderings.length > 0

        const lemma = MarbleLemmas.get(termId)
        if (!lemma) {
            return null
        }

        const termString = getTermSenseName(lemma, meaning)

        return (
            <div ref={meaningRef} className={isActiveSense ? 'er-highlighted-sense' : ''}>
                <div className="er-header">
                    <div className="er-lemma-title">{termString}</div>
                    {projectTerm?.isKeyTerm && (
                        <StarButton
                            enabled={starEnabled}
                            onClick={async () => {
                                toggleIsKeyTerm()
                            }}
                            className="er-star sl-fa-button"
                            buttonClassName="er-star-button"
                            tooltip={t('unsetAsBiblicalTerm')}
                        />
                    )}
                    {!projectTerm?.isKeyTerm && (
                        <HollowStarButton
                            enabled={starEnabled}
                            onClick={async () => {
                                toggleIsKeyTerm()
                            }}
                            className="er-star"
                            buttonClassName="er-star-button"
                            tooltip={t('setAsBiblicalTerm')}
                        />
                    )}
                    <span className="er-rendering-buttons">
                        <RecordButton
                            enabled={recordEnabled}
                            onClick={async () => {
                                const term = await saveProjectTermIfDoesNotExist()
                                if (term) {
                                    startRecording(term)
                                }
                            }}
                            className="er-record-sense"
                            tooltip={t('recordTermRendering')}
                            selectionPresent={false}
                        />
                        <PlayButton
                            enabled={projectTerm && projectTerm.renderings.length > 0}
                            onClick={() => {
                                if (projectTerm && projectTerm.renderings.length > 0) {
                                    setSignPlaying(projectTerm.renderings[0])
                                }
                            }}
                            className="er-play-sense"
                            tooltip={t('playTermRendering')}
                            selectionPresent={false}
                        />
                    </span>
                    <TrashButton
                        enabled={deleteEnabled}
                        onClick={() => {
                            confirmAlert({
                                title: t('deleteTermRenderingQuestionHeader'),
                                message: t('deleteTermRenderingQuestion'),
                                buttons: [
                                    {
                                        label: t('Cancel'),
                                        onClick: () => {}
                                    },
                                    {
                                        label: t('Delete'),
                                        onClick: () => {
                                            if (projectTerm && projectTerm.renderings.length > 0) {
                                                projectTerm.renderings[0].delete()
                                            }
                                        }
                                    }
                                ]
                            })
                        }}
                        className="er-delete-sense-rendering"
                        buttonClassName="er-delete-sense-rendering-button"
                        tooltip={t('deleteTermRendering')}
                    />
                </div>
                {meaning.definitionShort(language).map((definitionLink, key) => (
                    <ERDefinitionLinkView key={key} definitionLink={definitionLink} setTermId={setTermId} />
                ))}
                <div className="er-glosses">
                    <span className="er-label">{`${t('sourceGlossesHeader')}: `}</span>
                    <span className="er-explanation">
                        {meaning.glosses(language) || `<${t('No source glosses exist')}>`}
                    </span>
                </div>
                <TargetGlosses projectTerm={projectTerm} />
                <ProjectTermNotes rt={rt} notes={projectTerm ? projectTerm.notes : ''} t={t} save={setNotes} />
                <ERReferences {...{ rt, references: meaning.references, setProjectReference, projectTerm }} />
            </div>
        )
    }
)

type IERTermView = {
    rt: Root
    lemma: MarbleLemma
    setProjectReference: (projectReference: ProjectReference | null) => void
    termId: string
    setTermId: (resource: string) => void
    startRecording: (term: ProjectTerm) => void
    setSignPlaying: (signVideo: SignVideo) => void
}

const ERTermView: FC<IERTermView> = ({
    rt,
    lemma,
    setProjectReference,
    termId,
    setTermId,
    startRecording,
    setSignPlaying
}) => {
    return (
        <div className="er-term-div">
            {lemma.meanings.map((meaning, index) => (
                <React.Fragment key={meaning.id}>
                    {index > 0 && <hr />}
                    <ERMeaningView
                        {...{
                            rt,
                            setSignPlaying,
                            meaning,
                            setProjectReference,
                            termId,
                            setTermId,
                            index,
                            key: index,
                            startRecording
                        }}
                    />
                </React.Fragment>
            ))}
        </div>
    )
}

export default ERTermView
