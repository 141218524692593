import { FC } from 'react'

import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { Root } from '../../models3/Root'
import { VisibleTimestamp } from '../../models3/VisibleTimestamp'

import './Segments.css'
import '../video/Video.css'

interface ISegmentTimestamp {
    rt: Root
    passage: Passage
    passageSegment: PassageSegment
    currentSegment: PassageSegment
}

export const SegmentTimestamp: FC<ISegmentTimestamp> = ({ rt, passage, passageSegment, currentSegment }) => {
    const video = passage.findVideo(currentSegment._id)
    if (!video) return null // should never happen

    const isAPatch = passageSegment.isPatched && passageSegment._id !== currentSegment._id
    const className = isAPatch ? 'segment-selector-patch-time visible-timestamp' : 'visible-timestamp'

    return (
        <VisibleTimestamp
            name={video.creator}
            dateBy={video.creationDate}
            className={className}
            dateFormatter={rt.dateFormatter}
        />
    )
}
