import { encodeMp3 } from './Lame'
import { encodeOpus } from './Opus'
import { ambientNoiseBuffer, convertAudioBufferToWav, copyToNewBuffer, createSingleBuffer } from './Wav'
import { Passage } from '../../models3/Passage'
import { PassageVideo, VideoSlice } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { ViewableVideoCollection } from '../video/ViewableVideoCollection'
import { AudioContextFactory } from '../video/WaveformVisualizer'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('AudioProcessing:DownloadPassage')

export enum AudioEncodeType {
    wav = 'wav',
    mp3 = 'mp3',
    opus = 'opus'
}

export const DEFAULT_AUDIO_ENCODE_TYPE = AudioEncodeType.mp3

export const encodeAudio = (audioBlob: Blob, type: AudioEncodeType) => {
    switch (type) {
        case 'mp3':
            return encodeMp3(audioBlob)
        case 'opus':
            return encodeOpus(audioBlob)
        default:
            return audioBlob
    }
}

const getSingleBuffer = async (
    audioContext: AudioContext,
    slices: VideoSlice[],
    currentVideos: ViewableVideoCollection
) => {
    log('Started creating single buffer from slices', slices)

    const buffers = await Promise.all(
        slices.map(async (slice) => {
            const blob = await currentVideos.getBlob(slice.video)
            const buffer = await blob.arrayBuffer()
            const audioBuffer = await audioContext.decodeAudioData(buffer)
            const startPosition = slice.position * audioBuffer.sampleRate
            const endPosition = slice.endPosition * audioBuffer.sampleRate
            const channels = Array.from(Array(audioBuffer.numberOfChannels).keys())

            const channelDatas = channels.map((channel) =>
                audioBuffer.getChannelData(channel).slice(startPosition, endPosition)
            )
            return copyToNewBuffer(channelDatas, endPosition - startPosition, audioBuffer.sampleRate)
        })
    )
    const audioBuffer = createSingleBuffer(buffers)

    log('Completed creating single buffer', audioBuffer)
    return audioBuffer
}

const getPassageAudioRawData = async (audioContext: AudioContext, passage: Passage, passageVideo: PassageVideo) => {
    const vvc = new ViewableVideoCollection()
    vvc.setup(passage, passageVideo)
    vvc.download()

    const videos = await vvc.getViewableVideos()
    const isMissingParts = videos.length !== vvc.viewableVideos.length
    if (!videos.length) {
        return { audioBuffer: undefined, isMissingParts }
    }

    const slices = passageVideo
        .createSlicesWithNoGaps(passage, -1, -1)
        .filter((slice) => videos.some((vv) => slice.video._id === vv.video._id))
    const audioBuffer = await getSingleBuffer(audioContext, slices, vvc)
    return { audioBuffer, isMissingParts }
}

export const getPassageAudio = async (
    passage: Passage,
    passageVideo: PassageVideo,
    audioEncodeType: AudioEncodeType
) => {
    log(`Getting audio blob for passage ${passage.name}`)

    const audioContext = AudioContextFactory.getAudioContext()
    const { audioBuffer, isMissingParts } = await getPassageAudioRawData(audioContext, passage, passageVideo)
    if (!audioBuffer) {
        log(`No audio blob for passage ${passage.name}`)
        return { blob: undefined, isMissingParts }
    }
    const wavBlob = await convertAudioBufferToWav(audioBuffer)

    log(`Completed conversion to wav for ${passage.name}`)

    const blob = await encodeAudio(wavBlob, audioEncodeType)

    return { blob, isMissingParts }
}

export const getPortionAudio = async (portion: Portion, secondsOfSilence: number, audioEncodeType: AudioEncodeType) => {
    log(`Getting audio blob for portion ${portion.name}`)

    const audioContext = AudioContextFactory.getAudioContext()
    const passageRecordings = await Promise.all(
        portion.passages
            .filter((passage) => passage.videosNotDeleted.length)
            .map(async (passage) => {
                const latestDraft = passage.latestVideo
                return latestDraft
                    ? getPassageAudioRawData(audioContext, passage, latestDraft)
                    : { audioBuffer: undefined, isMissingParts: false }
            })
    )

    const buffers: AudioBuffer[] = []
    for (const { audioBuffer } of passageRecordings) {
        if (audioBuffer) {
            if (buffers.length && secondsOfSilence) {
                buffers.push(ambientNoiseBuffer(audioContext, secondsOfSilence))
            }
            buffers.push(audioBuffer)
        }
    }

    const isMissingParts = passageRecordings.some((recording) => recording.isMissingParts)

    if (!buffers.length) {
        log(`No audio to export for portion ${portion.name}`)
        return { blob: undefined, isMissingParts }
    }

    const wavBlob = await convertAudioBufferToWav(createSingleBuffer(buffers))

    log(`Completed conversion to wav for ${portion.name}`)

    const blob = await encodeAudio(wavBlob, audioEncodeType)

    return { blob, isMissingParts }
}
