import React, { FC } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import BookNameEditor from './BookNameEditor'
import { Root } from '../../../models3/Root'

import '../ProjectSettings.css'

interface IBookNameView {
    bookName: string
}

export const BookNameView: FC<IBookNameView> = ({ bookName }) => {
    const { t } = useTranslation()

    return bookName ? (
        <span>{bookName}</span>
    ) : (
        <i className="project-references-empty-book-name">{t('Book name...')}</i>
    )
}
interface IBookNameRow {
    rt: Root
    index: number
    uiBookName: string
    projectBookName: string
    saveProjectBookName: (bookName: string) => void
}

const BookNameRow: FC<IBookNameRow> = ({ rt, index, uiBookName, projectBookName, saveProjectBookName }) => {
    const { iAmAdmin, useMobileLayout } = rt

    return (
        <tr className="project-references-book-row">
            <td>{index + 1}</td>
            <td className="project-references-table-item">{uiBookName}</td>
            <td className="project-references-table-item">
                {iAmAdmin && !useMobileLayout ? (
                    <BookNameEditor bookName={projectBookName} save={saveProjectBookName} />
                ) : (
                    <BookNameView bookName={projectBookName} />
                )}
            </td>
        </tr>
    )
}

export default observer(BookNameRow)
