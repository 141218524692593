// Show a horizontal timeline with a vertical bar indicating current position in video.
// Allow user to set video position by clicking or dragging.

import React from 'react'

import { observable } from 'mobx'
import _ from 'underscore'

import { Passage } from '../../models3/Passage'
import { IDrawablePassageGloss } from '../../models3/PassageGloss'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'
import { ReferenceMarker } from '../../models3/ReferenceMarker'
import { Timeline } from '../../models3/RootBase'
import { RefRange } from '../../resources/RefRange'

export interface IVideoPosition {
    currentTime: number
    iAmInterpreter: boolean
    resetCurrentTime: (time: number) => void
    duration: number
    timeline: Timeline
    passage: Passage | null
    passageVideo: PassageVideo | null
    drawableGloss: IDrawablePassageGloss | null
    useMobileLayout: boolean
    setDbsRefs: (portion: Portion) => void
    dbsRefs: RefRange[]
    parseReferences: (references: string, allowBookNameOnly: boolean) => RefRange[]
    getSuggestions: (references: string) => RefRange[]
    project: Project
    portion: Portion | null
    passageSegment: PassageSegment | null
    displayableReferences: (references: RefRange[] | undefined | null) => string
    setDefault: (tag: string, value: string | null) => void
    getDefault: (tag: string) => string | null
    verseReference?: ReferenceMarker
}

interface IPoint {
    x: number
    y: number
}

const MIN_DRAG_DISTANCE_PIXELS = 10

export class CanvasDragAndClickDetector {
    @observable mousePosition: IPoint = { x: -1, y: -1 }

    @observable dragStart: IPoint = { x: -1, y: -1 }

    maxDistanceDraggedFromStart = 0

    @observable mousedown = false

    constructor(
        private canvasRef: React.RefObject<HTMLCanvasElement>,
        private onClick: (x: number, y: number) => void,
        private onDrag: (startX: number, startY: number, currentX: number, currentY: number) => void,
        private onDragEnd: (startX: number, startY: number, endX: number, endY: number) => void
    ) {
        this.onDrag = _.throttle(this.onDrag, 100)
        this.displayXToModelX = this.displayXToModelX.bind(this)
        this.displayYToModelY = this.displayYToModelY.bind(this)
        this._mousedown = this._mousedown.bind(this)
        this.mouseup = this.mouseup.bind(this)
        this.mousemove = this.mousemove.bind(this)
        this.mouseleave = this.mouseleave.bind(this)
        const { current } = canvasRef
        if (current) {
            current.addEventListener('mousedown', this._mousedown)
            current.addEventListener('mouseup', this.mouseup)
            current.addEventListener('mousemove', this.mousemove)
            current.addEventListener('mouseleave', this.mouseleave)
        }
    }

    dispose() {
        const { current } = this.canvasRef
        if (current) {
            current.removeEventListener('mousedown', this._mousedown)
            current.removeEventListener('mouseup', this.mouseup)
            current.removeEventListener('mousemove', this.mousemove)
            current.removeEventListener('mouseleave', this.mouseleave)
        }
    }

    displayYToModelY(e: React.MouseEvent) {
        const boundingRect = e.currentTarget.getBoundingClientRect()
        const y = e.clientY - boundingRect.top
        const { current } = this.canvasRef
        const canvasHeight = current?.height || 0
        const canvasOffsetHeight = current?.offsetHeight || 1 // prevent divide by 0
        return y * (canvasHeight / canvasOffsetHeight)
    }

    displayXToModelX(e: React.MouseEvent) {
        const boundingRect = e.currentTarget.getBoundingClientRect()
        const x = e.clientX - boundingRect.left
        const { current } = this.canvasRef
        const canvasWidth = current?.width || 0
        const canvasOffsetWidth = current?.offsetWidth || 1 // prevent divide by 0
        return x * (canvasWidth / canvasOffsetWidth)
    }

    mouseup() {
        const { maxDistanceDraggedFromStart } = this
        const { x, y } = this.mousePosition
        const { x: dragStartX, y: dragStartY } = this.dragStart
        const didDrag = dragStartY > -1 && dragStartX > -1 && maxDistanceDraggedFromStart >= MIN_DRAG_DISTANCE_PIXELS
        if (didDrag) {
            this.onDragEnd(dragStartX, dragStartY, x, y)
        } else {
            this.onClick(x, y)
        }
        this.mousedown = false
        this.dragStart = { x: -1, y: -1 }
        this.maxDistanceDraggedFromStart = 0
    }

    mousemove(e: any) {
        const { mousedown } = this
        const x = this.displayXToModelX(e)
        const y = this.displayYToModelY(e)
        this.mousePosition = { x, y }
        const { x: dragStartX, y: dragStartY } = this.dragStart
        if (mousedown) {
            this.maxDistanceDraggedFromStart = Math.max(this.maxDistanceDraggedFromStart, Math.abs(x - dragStartX))
            const isDragging =
                Math.abs(x - dragStartX) >= MIN_DRAG_DISTANCE_PIXELS ||
                this.maxDistanceDraggedFromStart >= MIN_DRAG_DISTANCE_PIXELS
            if (isDragging) {
                this.onDrag(dragStartX, dragStartY, x, y)
            }
        }
    }

    _mousedown() {
        const { x, y } = this.mousePosition
        this.dragStart = { x, y }
        this.mousedown = true
    }

    mouseleave() {
        this.mousePosition = { x: -1, y: -1 }
        this.dragStart = { x: -1, y: -1 }
        this.mousedown = false
        this.maxDistanceDraggedFromStart = 0
    }
}
