import { FC, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { IVideoPosition } from './VideoPositionBar'
import { ReferenceMarker } from '../../models3/ReferenceMarker'
import { RefRange } from '../../resources/RefRange'
import { ReferenceInput } from '../referenceInput/ReferenceInput'
import { TrashButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import { useOnClickOutside } from '../utils/Hooks'
import { WarningList } from '../utils/WarningList'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VerseReferenceEditor')

export const createVerseReference = async (videoPosition: IVideoPosition) => {
    const { currentTime, portion, passage, passageVideo, iAmInterpreter, project } = videoPosition
    if (!portion || !passage || !passageVideo || !iAmInterpreter) return

    const visibleReferences = passageVideo.getVisibleReferenceMarkers(passage)
    const tooCloseToReference = ReferenceMarker.tooClose(visibleReferences, currentTime)
    if (tooCloseToReference) return

    const reference = await passageVideo.addNextReferenceInSequence(
        portion,
        passage,
        currentTime,
        project.versification
    )
    videoPosition.verseReference = reference
}

const OldMarkerWarning = () => {
    const { t } = useTranslation()
    return <WarningList warnings={[{ text: t('markerOlderThanSegmentWarning'), key: 'old-marker-warning' }]} />
}

type VerseReferenceProps = {
    videoPosition: IVideoPosition
}

export const VerseReferenceEditor: FC<VerseReferenceProps> = observer((props) => {
    const { t } = useTranslation()
    const { verseReference, passage } = props.videoPosition
    const [errored, setErrored] = useState(false)
    const initRefs: RefRange[] = []
    const [refs, setRefs] = useState(verseReference?.references ?? initRefs)
    const referenceRef = useRef<any>(null)

    if (!verseReference || !passage) {
        return null
    }

    const closeEditor = () => {
        props.videoPosition.verseReference = undefined
    }

    const save = () => {
        log('_save', JSON.stringify(refs))
        verseReference.setReferences(refs).catch(displayError)
        // update current verse refs shown
        props.videoPosition.dbsRefs = refs
        closeEditor()
    }

    const deleteReference = async () => {
        const video = passage.findVideo(verseReference._id)
        if (video) {
            await video.removeReference(verseReference._id)
        }

        closeEditor()
    }

    const onEscape = async () => {
        // If we create a new reference but then hit escape, it is unwanted, delete it
        if (verseReference.newlyCreated) {
            await deleteReference()
        }
        closeEditor()
    }

    useOnClickOutside(referenceRef, () => {
        // Originally the following lines were wrapped in a useCallback.
        // When I did that it ended up using an old value of refs, so I removed it.
        log('useOnClickOutside', errored, JSON.stringify(refs))
        if (!errored) {
            verseReference.setReferences(refs).catch(displayError)
        }
        closeEditor()
    })

    return (
        <div className="verse-reference-editor" ref={referenceRef}>
            <ReferenceInput
                refInput={props.videoPosition}
                refs={refs}
                setRefs={setRefs}
                errored={errored}
                setErrored={setErrored}
                onEnter={save}
                onEscape={onEscape}
                autoFocus
                required
            />
            <TrashButton
                onClick={() => deleteReference()}
                buttonClassName=""
                className="verse-reference-delete-button"
                enabled
                tooltip={t('Delete verse reference')}
            />
            {verseReference.isOlderThanContainingPassageVideo(passage) && <OldMarkerWarning />}
        </div>
    )
})
