// VideoToolbar allows controlling playing and recording by means of actions and variable of rt

import React, { FC, useEffect, useState } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'

import { createVerseReference } from './VerseReferenceEditor'
import { AVTTRecordingState } from './VideoRecorder'
import { downloadPassageRecording, VideoToolbarCopyMenuItems } from './VideoToolbarCopyMenuItems'
import { VideoToolbarReviewMenuItems } from './VideoToolbarReviewMenuItems'
import { AudioContextFactory } from './WaveformVisualizer'
import { MediaSlice } from '../../models3/MediaSlice'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { MIN_SEGMENT_LENGTH } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { ExportSourceType, RecordingType } from '../../types'
import { BaseRecordingFilePicker } from '../filePickers/BaseRecordingFilePicker'
import { ExportModal } from '../modals/export/ExportModal'
import PassageTitleModal from '../modals/passage/PassageTitleModal'
import { UnresolvedTextNotification } from '../notifications/Notifications'
import PassageVideoSelector from '../passages/PassageVideoSelector'
import {
    RecordButton,
    StopButton,
    CreateNoteButton,
    TrashButton,
    PlayButton,
    PauseButton,
    SlttHelp,
    CutButton,
    KeyTermButton,
    IconButton
} from '../utils/Buttons'
import { displayError, displayInfo, systemError } from '../utils/Errors'
import {
    safeFileName,
    downloadWithFilename,
    createLink,
    AudioSection,
    decrementTime,
    incrementTime
} from '../utils/Helpers'
import {
    ClipboardIcon,
    DownloadIcon,
    EllipsisIcon,
    PassageSegmentsIcon,
    PassageVideoReferenceIcon,
    PencilIcon
} from '../utils/Icons'
import { encodeOpus } from '../utils/Opus'
import VideoCompressor from '../utils/VideoCompressor'
import { deleteAudioSection } from '../utils/Wav'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoToolbar')

const getSlicedAudio = async (slice: MediaSlice, sectionsToKeepSeconds: AudioSection[]) => {
    const audioContext = AudioContextFactory.getAudioContext()
    const wavBlob = await deleteAudioSection(audioContext, slice.src, sectionsToKeepSeconds)
    return encodeOpus(wavBlob)
}

const getSlicedVideo = async (passage: Passage, project: Project, rt: Root, sectionsToKeepSeconds: AudioSection[]) => {
    if (!(await VideoCompressor.checkIfServerRunning())) {
        return
    }
    const { currentVideos } = rt
    const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)
    const { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = project
    const compressor = new VideoCompressor(
        {
            crf: compressedVideoQuality,
            resolution: compressedVideoResolution,
            maxFileSizeMB: maxVideoSizeMB,
            rescaleVideo: false
        },
        setProgressMessage
    )
    return compressor.deleteSection(currentVideos, passage, sectionsToKeepSeconds)
}

const deleteRecordingSection = async (rt: Root) => {
    try {
        const { passage, name, passageVideo, passageSegment, timeline } = rt
        if (
            !timeline.selectionPresent() ||
            !passage ||
            !passageVideo ||
            !passageSegment ||
            !passageSegment.actualVideo(passage)
        ) {
            return
        }

        const actualSegment = passageSegment.actualSegment(passage)
        const slices = await actualSegment.getPlayableSlicesForViewablePartOfSegment(passage)
        if (!slices.length) {
            return
        }

        const { selectionStartTime, selectionEndTime } = timeline.getSelectionTimes()
        const slice = slices[0]

        let slicedFile
        if (passageVideo.isAudioOnly()) {
            const sectionsToKeep: AudioSection[] = [
                { start: slice.startPosition, end: actualSegment.timeToPosition(selectionStartTime) },
                { start: actualSegment.timeToPosition(selectionEndTime), end: slice.endPosition }
            ]
            slicedFile = await getSlicedAudio(slice, sectionsToKeep)
        } else {
            // make this a little smaller so we know it is in the current segment and not the next one
            const sectionsToKeep: AudioSection[] = [
                {
                    start: actualSegment.positionToTime(slice.startPosition),
                    end: decrementTime(selectionStartTime)
                },
                {
                    start: selectionEndTime,
                    end: decrementTime(actualSegment.positionToTime(slice.endPosition))
                }
            ]
            slicedFile = await getSlicedVideo(passage, rt.project, rt, sectionsToKeep)
        }
        if (!slicedFile) {
            return
        }
        const patch = await passage.uploadFile(slicedFile, name)
        await passage.addPatchVideo({
            baseRecording: passageVideo,
            patch,
            baseSegment: passageSegment,
            onTopSegment: passageSegment.actualSegment(passage)
        })
        rt.setPassageVideo(passageVideo)
        rt.setPassageSegment(passageSegment)
        rt.resetCurrentTime(incrementTime(passageSegment.actualSegment(passage).time))
    } catch (error) {
        systemError(error)
    }
}

const allowRecordingSectionDeletion = (rt: Root) => {
    if (!rt.timeline.selectionPresent()) {
        return false
    }

    const { passageVideo, passage, passageSegment, iAmTranslatorForPassage } = rt
    const { reviewProject } = rt.project

    if (
        !(
            iAmTranslatorForPassage(passage) &&
            passageVideo &&
            passage &&
            passageSegment &&
            passageVideo.getVisibleBaseSegments().length > 1
        )
    ) {
        return false
    }

    const isInReview = passageVideo.isInReview(reviewProject)
    if (isInReview) {
        return false
    }

    const { selectionStartTime, selectionEndTime } = rt.timeline.getSelectionTimes()
    const startIndex = passageVideo.timeToSegmentIndex(selectionStartTime)
    const endIndex = passageVideo.timeToSegmentIndex(selectionEndTime)
    if (startIndex === -1 || endIndex === -1 || startIndex !== endIndex) {
        return false
    }

    const actualSegment = passageSegment.actualSegment(passage)
    if (
        actualSegment.endPosition - actualSegment.position - (selectionEndTime - selectionStartTime) <
        MIN_SEGMENT_LENGTH
    ) {
        return false
    }

    return true
}

async function createLinkToVideo(rt: Root) {
    const { passageVideo, currentTime, project } = rt
    if (!passageVideo) {
        return
    }
    const text = createLink({ projectName: project.name, itemId: passageVideo._id, time: currentTime })
    try {
        await navigator.clipboard.writeText(text)
        displayInfo(t('Link copied to clipboard. Only your team can view this link.'))
    } catch (error) {
        displayError(error, t('Failed to copy!'))
    }
}

type RareFunctionsDropdownProps = {
    rt: Root
    enabled?: boolean
    openCopyPassageModal?: (passageToCopy: Passage) => void
}

const RareFunctionsDropdown: FC<RareFunctionsDropdownProps> = observer(({ rt, enabled }) => {
    const [blob, setBlob] = useState<Blob>()
    const [exportModalOpen, setExportModalOpen] = useState(false)
    const [isCopyNewVersionModalOpen, setIsCopyNewVersionModalOpen] = useState(false)
    const [isCopyToDocumentModalOpen, setIsCopyToDocumentModalOpen] = useState(false)
    const [editTitleOpen, setEditTitleOpen] = useState(false)

    const {
        portion,
        passage,
        passageVideo,
        canPlayThrough,
        currentVideos,
        dateFormatter,
        iAmTranslatorForPassage,
        useMobileLayout
    } = rt

    const compressingVideo = passage?.videoBeingCompressed || false
    const compressorAvailable = !useMobileLayout && canPlayThrough && !compressingVideo

    useEffect(() => {
        let blobHref = ''

        if (blob && passage) {
            blobHref = URL.createObjectURL(blob)
            const fileExtension = blob.type.startsWith('audio/')
                ? blob.type.endsWith('opus')
                    ? '.opus'
                    : '.wav'
                : '.mp4'
            let fileName = `${passage.name}-${dateFormatter.format(new Date(Date.now()))}`
            fileName = `${safeFileName(fileName)}${fileExtension}`
            downloadWithFilename(blobHref, fileName)
        }

        return () => {
            if (blobHref) {
                URL.revokeObjectURL(blobHref)
            }
        }
    }, [blob, dateFormatter, passage])

    if (!passage || !passageVideo) {
        return null // do not show the dropdown menu at all if no recordings
    }

    const className = 'video-toolbar-hamburger-menu-item'
    const downloadIconClassName = 'sl-download-full-video-icon fa-fw'

    return (
        <Dropdown className="video-toolbar-hamburger-menu" id="video-toolbar-hamburger-menu" align="end">
            <Dropdown.Toggle
                className="styled-dropdown-select  video-toolbar-hamburger-menu-toggle"
                disabled={!enabled}
            >
                <EllipsisIcon className="main-video-see-more-icon sl-fa-button" tooltip={t('See more options')} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="styled-dropdown-select-menu">
                {editTitleOpen && passageVideo && (
                    <PassageTitleModal closeModal={() => setEditTitleOpen(false)} passageVideo={passageVideo} />
                )}
                {exportModalOpen && portion && (
                    <ExportModal
                        rt={rt}
                        exportSourceType={ExportSourceType.PASSAGE_VIDEO}
                        portion={portion}
                        setOpen={setExportModalOpen}
                    />
                )}
                {!currentVideos.viewableVideos.some((vv) => vv.video.isAudioOnly()) && (
                    <Dropdown.Item
                        className={className}
                        onClick={async () => {
                            const recording = await downloadPassageRecording(rt, t)
                            if (recording) {
                                setBlob(recording.blob)
                            }
                        }}
                        disabled={!compressorAvailable}
                    >
                        <>
                            <DownloadIcon className={downloadIconClassName} tooltip="" />
                            {t('Export video')}
                        </>
                    </Dropdown.Item>
                )}
                {!useMobileLayout && (
                    <>
                        <Dropdown.Item
                            className={className}
                            onClick={() => setExportModalOpen(true)}
                            disabled={compressingVideo}
                        >
                            <>
                                <DownloadIcon className={downloadIconClassName} tooltip="" />
                                {t('Export')}
                            </>
                        </Dropdown.Item>
                        <Dropdown.Item
                            className={className}
                            onClick={() => setEditTitleOpen(true)}
                            disabled={!iAmTranslatorForPassage(passage)}
                        >
                            <>
                                <PencilIcon
                                    className={downloadIconClassName}
                                    tooltip=""
                                    enabled={iAmTranslatorForPassage(passage)}
                                />
                                {t('editRecordingVersion')}
                            </>
                        </Dropdown.Item>
                        <VideoToolbarReviewMenuItems rt={rt} className={className} />
                        <VideoToolbarCopyMenuItems
                            rt={rt}
                            className={className}
                            isCopyNewVersionModalOpen={isCopyNewVersionModalOpen}
                            setIsCopyNewVersionModalOpen={setIsCopyNewVersionModalOpen}
                            isCopyToDocumentModalOpen={isCopyToDocumentModalOpen}
                            setIsCopyToDocumentModalOpen={setIsCopyToDocumentModalOpen}
                        />
                    </>
                )}
                <Dropdown.Item className={className} onClick={() => createLinkToVideo(rt)}>
                    <>
                        <ClipboardIcon className="main-video-clipboard-icon fa-fw" tooltip="" />
                        {t('recordingCopyLinkForRecording')}
                    </>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
})

interface IVideoToolbar {
    rt: Root
    playAllVideos: () => void
    playCurrentVideo: () => void
    pausePlayback: () => void
    pauseRecording: () => void
    record: (recordingType: RecordingType) => void
    stopRecording: () => void
    recordingState: AVTTRecordingState
    resumeRecording: () => void
    openBiblicalTermMarkerEditor: () => void
}

class VideoToolbar extends React.Component<IVideoToolbar> {
    onSelectVideo = async (video: PassageVideo) => {
        if (video.removed) {
            await this.undeleteVideo(video)
        } else {
            await this.makeSelection(video)
        }
    }

    goToNote = async (note: PassageNote) => {
        const { rt } = this.props
        const { passage } = rt
        if (!passage) {
            return
        }
        let video = passage.findVideo(note._id) || null
        video = video?.baseVideo(passage) || video
        await rt.setPassage(passage)
        await rt.setPassageVideo(video)
        rt.setNote(note)
    }

    createPassageSegment = () => {
        const _createPassageSegment = async () => {
            const { rt } = this.props
            const { passage, passageVideo, passageSegment } = rt
            if (!passage || !passageVideo || !passageSegment || passageSegment.isPatched) return
            const position = passageVideo.timeToPosition(passage, rt.currentTime)
            if (position !== undefined) {
                await passageVideo.addSegment({ position })
                rt.resetCurrentTime(rt.currentTime) // will force current segment to be set
            } else {
                throw Error(`${t('System Error')}: PassageVideo, Passage, or PassageSegment should not be null`)
            }
        }

        _createPassageSegment().catch(displayError)
    }

    deleteVideo = async () => {
        const { rt } = this.props
        const { passage, passageVideo } = rt
        const { reviewProject } = rt.project
        if (!passage || !passageVideo) {
            displayError(`${t('System Error')}: Status change failed`)
            return
        }

        this.confirmDeletion(() => {
            if (!passageVideo) {
                displayError(`${t('System Error')}: Status change failed`)
                return
            }

            passage
                .removeVideo(passageVideo._id)
                .then(() => {
                    if (reviewProject) {
                        passageVideo.getLatestReviewRecording(reviewProject)?.setIsActive(false)
                    }
                    const i = passage.videosNotDeleted.length
                    rt.setPassageVideo(i > 0 ? passage.videosNotDeleted[i - 1] : null)
                })
                .catch(displayError)
        })
    }

    confirmDeletion(doDeletion: () => void) {
        confirmAlert({
            title: t('recordingDeleteQuestion'),
            message: t('recordingDeleteWarning'),
            buttons: [
                {
                    label: t('recordingKeep'),
                    onClick: () => {}
                },
                {
                    label: t('recordingDelete'),
                    onClick: doDeletion
                }
            ]
        })
    }

    async undeleteVideo(passageVideo: PassageVideo) {
        const { rt } = this.props
        const { passage, iAmAdmin } = rt
        if (!passage || !iAmAdmin) return

        try {
            await passage.undeleteVideo(passageVideo)
            await this.makeSelection(passageVideo)
        } catch (error) {
            displayError(error)
        }
    }

    async makeSelection(passageVideo: PassageVideo) {
        const { rt } = this.props
        const { passage } = rt
        if (!passage) return

        try {
            await rt.setPassageVideo(passageVideo)
        } catch (error) {
            displayError(error)
        }
    }

    render() {
        const {
            rt,
            playAllVideos,
            playCurrentVideo,
            record,
            stopRecording,
            recordingState,
            resumeRecording,
            pausePlayback,
            pauseRecording,
            openBiblicalTermMarkerEditor
        } = this.props
        const {
            passage,
            passageVideo,
            passageSegment,
            playing,
            recording,
            hardNotificationCutoff,
            iAmInterpreter,
            iAmTranslatorForPassage,
            canViewConsultantOnlyFeatures,
            useMobileLayout,
            canPlayThrough,
            project
        } = rt
        const { plans, reviewProject } = project

        const selectionPresent = rt.timeline.selectionPresent()

        const compressingVideo = passage?.videoBeingCompressed || false
        const enabled = !compressingVideo

        const stopEnabled = enabled && (recordingState === 'RECORDING' || recordingState === 'PAUSED')
        const isNotPlayingNorRecording = enabled && !recording && !playing

        const playEnabled = canPlayThrough && !recording && enabled && !!passageVideo

        const recordEnabled =
            (passage && isNotPlayingNorRecording && iAmTranslatorForPassage(passage)) ||
            ['INITIALIZED', 'PAUSED'].includes(recordingState)
        const createNoteEnabled = !!passageVideo && isNotPlayingNorRecording && iAmInterpreter

        const createSegmentEnabled =
            isNotPlayingNorRecording &&
            !!passageVideo &&
            !!passageSegment &&
            !passageSegment.isPatched &&
            iAmInterpreter
        const verseReferenceEnabled = Boolean(passageVideo) && isNotPlayingNorRecording && iAmInterpreter
        const cutButtonEnabled = isNotPlayingNorRecording && allowRecordingSectionDeletion(rt)
        const createBiblicalTermMarkerEnabled = Boolean(passageVideo) && isNotPlayingNorRecording && iAmInterpreter

        const dropdownVideos = passage
            ? (rt.iAmAdmin ? passage.videos : passage.videosNotDeleted).filter((v) => !v.isPatch)
            : []

        const cutoff = hardNotificationCutoff()
        const activeVideos = dropdownVideos.filter((v) => !v.removed)
        let unresolvedNote: PassageNote | null = null
        if (passage && activeVideos.length) {
            unresolvedNote = activeVideos[activeVideos.length - 1].mostRecentUnresolvedNote(
                passage,
                cutoff,
                canViewConsultantOnlyFeatures
            )
        }

        const displayDeleteVideo = !!passageVideo
        const unresolvedTextNotificationDisplayed = !!passage && unresolvedNote && !!passageVideo
        const unresolvedTextNotificationEnabled = unresolvedTextNotificationDisplayed && enabled
        const displayPassageVideoSelector = !!passage && dropdownVideos.length > 0
        const changeMainVideoEnabled = displayPassageVideoSelector && enabled && isNotPlayingNorRecording

        // If I try to move the following to a .css file it stops working.
        // I have no idea why.
        const passageSegmentIconStyle = {
            verticalAlign: 'middle'
        }

        return (
            <div className="video-toolbar">
                <div className="video-toolbar-left">
                    {playing && (
                        <>
                            <PauseButton
                                className="main-video-pause-button"
                                enabled={enabled}
                                tooltip={t('Pause.')}
                                onClick={() => pausePlayback()}
                            />
                            <SlttHelp id="passages-record" tooltip={t('recordPassage')} place="bottom">
                                <RecordButton
                                    tooltip=""
                                    selectionPresent={false}
                                    enabled={false}
                                    className="sl-record-button sl-record-video-button"
                                    onClick={() => {}}
                                />
                            </SlttHelp>
                        </>
                    )}
                    {!playing && !recording && (
                        <>
                            <PlayButton
                                enabled={playEnabled}
                                selectionPresent={selectionPresent}
                                tooltip={selectionPresent ? t('Play selection.') : t('Play.')}
                                className="main-video-play-button"
                                onClick={async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                    if (e.shiftKey) {
                                        playAllVideos()
                                    } else {
                                        playCurrentVideo()
                                    }
                                }}
                            />
                            <SlttHelp id="passages-record" tooltip={t('recordPassage')} place="bottom">
                                <RecordButton
                                    tooltip=""
                                    selectionPresent={false}
                                    enabled={recordEnabled}
                                    className="sl-record-button sl-record-video-button"
                                    onClick={() => {
                                        record(RecordingType.BASE)
                                    }}
                                />
                            </SlttHelp>
                        </>
                    )}
                    {recording && recordingState === 'RECORDING' && (
                        <PauseButton
                            className="main-video-pause-button"
                            enabled={enabled}
                            tooltip={t('Pause.')}
                            onClick={() => pauseRecording()}
                        />
                    )}
                    {recording && recordingState !== 'RECORDING' && (
                        <RecordButton
                            tooltip={t('Continue recording')}
                            selectionPresent={false}
                            enabled={recordEnabled}
                            className="sl-record-button sl-record-video-button"
                            onClick={() => resumeRecording()}
                        />
                    )}
                    {recording && (
                        <StopButton
                            className="main-video-stop-button"
                            enabled={stopEnabled}
                            tooltip={t('Stop and save recording.')}
                            onClick={stopRecording}
                        />
                    )}

                    {passage && (
                        <BaseRecordingFilePicker
                            enabled={recordEnabled && !recording}
                            rt={rt}
                            passage={passage}
                            className="main-upload-file-button"
                        />
                    )}

                    {!useMobileLayout && (
                        <>
                            <SlttHelp id="segments-create" tooltip={t('recordingCreateNewSegment')} place="bottom">
                                <IconButton
                                    enabled={iAmInterpreter}
                                    onClick={this.createPassageSegment}
                                    contents={
                                        <PassageSegmentsIcon
                                            className="sl-create-passage-segment"
                                            style={passageSegmentIconStyle}
                                            enabled={createSegmentEnabled}
                                        />
                                    }
                                />
                            </SlttHelp>
                            <CreateNoteButton
                                enabled={createNoteEnabled}
                                onClick={() => {
                                    if (passage && passageVideo) {
                                        const notePosition = passageVideo.timeToPosition(passage, rt.currentTime)
                                        const video = passageVideo.timeToVideo(passage, rt.currentTime)
                                        log('createNote', rt.currentTime, notePosition, video._id)
                                        const note = video.createNote.bind(video)(notePosition)
                                        note.time = rt.currentTime
                                        rt.setNote(note)
                                    }
                                }}
                            />
                            <SlttHelp id="biblical-terms-insert" tooltip={t('createBiblicalTermMarker')} place="bottom">
                                <KeyTermButton
                                    className="video-toolbar-button key-term-marker-button"
                                    onClick={openBiblicalTermMarkerEditor}
                                    enabled={createBiblicalTermMarkerEnabled}
                                />
                            </SlttHelp>
                            <PassageVideoReferenceIcon
                                className="sl-create-passage-segment"
                                iconClassName=""
                                onClick={() => createVerseReference(rt)}
                                tooltip={t('Create new verse reference')}
                                enabled={verseReferenceEnabled}
                            />
                            <CutButton
                                onClick={() => deleteRecordingSection(rt)}
                                className="video-toolbar-button scissors-icon"
                                tooltip={t('Delete section')}
                                enabled={cutButtonEnabled}
                            />
                        </>
                    )}
                </div>

                <div className="video-toolbar-right">
                    {displayDeleteVideo && !useMobileLayout && (
                        <TrashButton
                            enabled={enabled && iAmTranslatorForPassage(passage) && isNotPlayingNorRecording}
                            buttonClassName=""
                            className="delete-video-button"
                            onClick={this.deleteVideo}
                            tooltip={t('recordingDelete')}
                        />
                    )}
                    {displayPassageVideoSelector && (
                        <PassageVideoSelector
                            enabled={changeMainVideoEnabled}
                            videos={dropdownVideos}
                            currentVisibleVideo={passageVideo || dropdownVideos[dropdownVideos.length - 1]}
                            onSelect={this.onSelectVideo}
                            passageVideoNotification={rt}
                            plan={plans.length ? plans[0] : undefined}
                            reviewProject={reviewProject}
                        />
                    )}
                    {unresolvedTextNotificationDisplayed && !useMobileLayout && (
                        <UnresolvedTextNotification
                            className="video-toolbar-notification sl-fa-button"
                            tooltip={t('Unresolved notes exist')}
                            onClick={async () => {
                                if (unresolvedTextNotificationEnabled && unresolvedNote) {
                                    this.goToNote(unresolvedNote)
                                }
                            }}
                        />
                    )}
                    <RareFunctionsDropdown {...{ rt, enabled: isNotPlayingNorRecording }} />
                </div>
            </div>
        )
    }
}

export default observer(VideoToolbar)
