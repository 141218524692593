import { useCallback, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import InputSlider from 'react-input-slider'
import SplitPane from 'react-split-pane'

import { VerseReferenceEditor } from './VerseReferenceEditor'
import { VideoMessage } from './VideoMessage'
import { VideoPositionBarControls } from './VideoPositionBarControls'
import { AVTTRecordingState } from './VideoRecorder'
import { LiveWaveformVisualizerWrapper } from './WaveformVisualizer'
import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { IDateFormatter } from '../../models3/DateUtilities'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { limit } from '../../models3/Utils'
import { VisibleTimestamp } from '../../models3/VisibleTimestamp'
import { WaveSurferAudioPlayer } from '../audio/WaveSurferAudioPlayer'
import ERTermModal from '../enhancedResources/ERTermModal'
import { SegmentsEditor } from '../segments/SegmentsEditor'
import ErrorBoundary from '../utils/Errors'

import './Video.css'

interface RecordingMetadataProps {
    reference: string
    passageVideo: PassageVideo
    dateFormatter: IDateFormatter
}

export const RecordingMetadata = observer(({ reference, passageVideo, dateFormatter }: RecordingMetadataProps) => {
    return (
        <div className="passage-video-timestamp">
            <span>{reference}</span>
            <VisibleTimestamp
                name={passageVideo.creator}
                dateBy={passageVideo.creationDate}
                className="visible-timestamp"
                dateFormatter={dateFormatter}
            />
        </div>
    )
})

type VideoMainBottomProps = {
    rt: Root
    recordingState: AVTTRecordingState
    mediaStream?: MediaStream
    setBiblicalTermMarker: (marker: BiblicalTermMarker) => void
    isAudioOnly: boolean
}

interface VideoMainBottomSplitTopProps {
    rt: Root
    recordingState: AVTTRecordingState
    mediaStream?: MediaStream
    isAudioOnly: boolean
}

const VideoMainBottomSplitTop = observer(
    ({ rt, recordingState, mediaStream, isAudioOnly }: VideoMainBottomSplitTopProps) => {
        const { passage } = rt
        const videoBeingCompressed = !!passage?.videoBeingCompressed

        if (videoBeingCompressed) {
            return <VideoMessage rt={rt} />
        }

        return (
            <LiveWaveformVisualizerWrapper
                {...{ mediaStream, recordingState, isAudioOnly, className: 'audio-waveform-visualizer' }}
            />
        )
    }
)

const MIN_MIN_PX_PER_SECOND = 50 // show about about 20 seconds initially
const MAX_MIN_PX_PER_SECOND = 200

export const VideoMainBottom = observer(
    ({ rt, mediaStream, recordingState, setBiblicalTermMarker, isAudioOnly }: VideoMainBottomProps) => {
        const [termId, setTermId] = useState('')
        const [waveSurferZoom, setWaveSurferZoom] = useState(MIN_MIN_PX_PER_SECOND)
        const [zoomOutEnabled, setZoomOutEnabled] = useState(true)

        const { t } = useTranslation()

        const { passageVideo, recording, passage, canPlayThrough, playbackRate, useMobileLayout } = rt

        const videoPositionControls = rt

        const enabled = !passage?.videoBeingCompressed
        const adjustTimeEnabled = canPlayThrough && enabled

        const onRateChange = (pos: any /* {x:, y: } */) => {
            const newPlaybackRate = 2.0 - pos.y
            rt.setPlaybackRate(newPlaybackRate)
        }

        // Wavesurfer calls this function. This function needs to be memoized or wavesurfer
        // will rebuild itself when this component rerenders.
        const memoizedSetMinPxPerSecond = useCallback((zoom: number) => {
            setWaveSurferZoom(limit(zoom, MIN_MIN_PX_PER_SECOND, MAX_MIN_PX_PER_SECOND))
        }, [])

        return (
            <div className="video-main-bottom">
                {termId.trim() !== '' && <ERTermModal rt={rt} termId={termId} setTermId={setTermId} />}
                {!recording && (
                    <>
                        {passageVideo && (
                            <VideoPositionBarControls
                                videoPositionControls={videoPositionControls}
                                zoomIn={() => setWaveSurferZoom(Math.min(waveSurferZoom * 2, MAX_MIN_PX_PER_SECOND))}
                                zoomOut={() => setWaveSurferZoom(waveSurferZoom / 2)}
                                zoomInEnabled={enabled && waveSurferZoom < MAX_MIN_PX_PER_SECOND}
                                zoomOutEnabled={enabled && zoomOutEnabled}
                                adjustTimeEnabled={adjustTimeEnabled}
                            />
                        )}
                        <div className="video-main-bottom-bottom">
                            <WaveSurferAudioPlayer
                                rt={rt}
                                minPxPerSecond={waveSurferZoom}
                                setMinPxPerSecond={memoizedSetMinPxPerSecond}
                                setBiblicalTermMarker={setBiblicalTermMarker}
                                setZoomOutEnabled={setZoomOutEnabled}
                            />
                            <div className="main-video-player-controls">
                                <div className="u-slider u-slider-y video-player-slider">
                                    <InputSlider
                                        className="slider video-rate-input-slider"
                                        slidertooltip={`${t('Playback speed')} = ${playbackRate.toFixed(1)}`}
                                        axis="y"
                                        y={2.0 - playbackRate}
                                        ymax={2}
                                        ystep={0.1}
                                        onChange={onRateChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {passageVideo && (
                            <RecordingMetadata
                                reference={rt.displayableReferences(rt.dbsRefs)}
                                dateFormatter={rt.dateFormatter}
                                passageVideo={passageVideo}
                            />
                        )}
                    </>
                )}
                {rt.verseReference && rt.iAmInterpreter && <VerseReferenceEditor videoPosition={rt} />}
                {recording && (
                    <div className="video-main-bottom-bottom">
                        <SplitPane
                            split="horizontal"
                            size={160}
                            style={{ position: 'relative', height: 'auto', overflow: 'visible', flex: 1 }}
                            allowResize={false}
                        >
                            <VideoMainBottomSplitTop
                                rt={rt}
                                recordingState={recordingState}
                                mediaStream={mediaStream}
                                isAudioOnly={isAudioOnly}
                            />
                            <div />
                        </SplitPane>
                        <div className="main-video-player-controls">
                            <div className="u-slider u-slider-y video-player-slider">
                                <InputSlider
                                    className="slider video-rate-input-slider"
                                    slidertooltip={`${t('Playback speed')} = ${playbackRate.toFixed(1)}`}
                                    axis="y"
                                    y={2.0 - playbackRate}
                                    ymax={2}
                                    ystep={0.1}
                                    onChange={onRateChange}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!useMobileLayout && (
                    <ErrorBoundary>
                        <SegmentsEditor rt={rt} recordingState={recordingState} />
                    </ErrorBoundary>
                )}
            </div>
        )
    }
)
