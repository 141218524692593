import { FC, useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { PassageVideo } from '../../../models3/PassageVideo'
import LabeledFieldWithIcon from '../../utils/LabeledFieldWithIcon'
import { SimpleTextInput } from '../../utils/TextInput'
import { ModalFooter } from '../Modals'

interface Props {
    closeModal: () => void
    passageVideo: PassageVideo
}

const PassageTitleModal: FC<Props> = ({ closeModal, passageVideo }) => {
    const { t } = useTranslation()
    const [passageVideoTitle, setPassageVideoTitle] = useState(passageVideo.title)

    const onSave = async () => {
        await passageVideo.setTitle(passageVideoTitle)
        closeModal()
    }

    return (
        <Modal backdrop="static" onHide={closeModal} show>
            <Modal.Header closeButton>
                <Modal.Title>{t('editRecordingVersion')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LabeledFieldWithIcon
                    iconName="fa-tag"
                    title={t('Title')}
                    field={
                        <SimpleTextInput
                            value={passageVideoTitle}
                            setValue={setPassageVideoTitle}
                            errorMessage=""
                            autoFocus
                        />
                    }
                />
            </Modal.Body>
            <ModalFooter
                enabledCancel
                onOK={onSave}
                onCancel={closeModal}
                okTooltip={t('OK')}
                cancelTooltip={t('Cancel')}
            />
        </Modal>
    )
}

export default PassageTitleModal
