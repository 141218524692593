import { FC, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'
import { MultiSelect as ReactMultiSelect } from 'react-multi-select-component'

import './MultiSelect.css'

export interface Option<T> {
    label: string
    value: T
    disabled?: boolean
}
export interface ItemRendererProps<T> {
    checked: boolean
    option: Option<T>
    onClick: () => void
    disabled?: boolean
}

interface Props<T> {
    options: Option<T>[]
    selected: Option<T>[]
    setSelected: (selected: Option<T>[]) => void
    singleSelect?: boolean
    ItemRenderer?: FC<ItemRendererProps<T>>
    ClearSelectedIcon?: ReactNode
    disabled?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const MultiSelect = <T extends unknown>({
    options,
    selected,
    setSelected,
    singleSelect = false,
    ItemRenderer,
    ClearSelectedIcon,
    disabled
}: Props<T>) => {
    const { t } = useTranslation()
    return (
        <ReactMultiSelect
            options={options}
            value={selected}
            hasSelectAll={!singleSelect && options.length > 1}
            disabled={disabled}
            onChange={(values: Option<T>[]) => {
                if (singleSelect) {
                    setSelected(values.length < 1 ? values : [values[values.length - 1]])
                } else {
                    setSelected(values)
                }
            }}
            ClearSelectedIcon={ClearSelectedIcon}
            ItemRenderer={ItemRenderer}
            labelledBy="Select"
            overrideStrings={{
                allItemsAreSelected: t('multiSelectAllItemsAreSelected'),
                clearSearch: t('multiSelectClearSearch'),
                clearSelected: t('multiSelectClearSelected'),
                noOptions: t('multiSelectNoOptions'),
                search: t('multiSelectSearch'),
                selectAll: t('multiSelectSelectAll'),
                selectAllFiltered: t('multiSelectSelectAllFiltered'),
                selectSomeItems: singleSelect ? t('selectOne') : t('multiSelectSelectSomeItems'),
                create: t('multiSelectCreate')
            }}
        />
    )
}
