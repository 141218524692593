import { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { PassageSegment } from '../../models3/PassageSegment'
import { Root } from '../../models3/Root'
import { RecordingType } from '../../types'
import { PreviousSegmentButton, NextSegmentButton, GenericIconButton } from '../utils/Buttons'

interface SegmentButtonsProps {
    rt: Root
    segmentIndex: number
    segments: PassageSegment[]
    record: (recordingType: RecordingType) => void
    isNotPlayingNorRecording: boolean
    isInReview: boolean
}

const SegmentButtons = observer(
    ({ rt, segmentIndex, segments, record, isNotPlayingNorRecording, isInReview }: SegmentButtonsProps) => {
        const { t, i18n } = useTranslation()
        const dir = i18n.dir()
        const { passage, videoPlaybackKeydownEnabled, passageVideo, iAmTranslatorForPassage } = rt

        const gotoSegment = function (seg: PassageSegment) {
            rt.resetCurrentTime(seg.time)
        }

        useEffect(() => {
            const keydown = (e: KeyboardEvent) => {
                const element = (e.target && e.target.toString()) || ''
                const el = e.target as Element
                const shouldReject =
                    element.includes('HTMLInputElement') ||
                    element.includes('HTMLTextAreaElement') ||
                    (el.getAttribute && el.getAttribute('contenteditable') === 'true') ||
                    (el.getAttribute && el.getAttribute('role') === 'dialog') ||
                    !videoPlaybackKeydownEnabled ||
                    passage?.videoBeingCompressed
                if (shouldReject) {
                    return
                }

                e.stopPropagation()

                if (e.key === '<') {
                    if (segmentIndex > 0) {
                        gotoSegment(segments[segmentIndex - 1])
                    }
                }

                if (e.key === '>') {
                    if (segmentIndex < segments.length - 1) {
                        gotoSegment(segments[segmentIndex + 1])
                    }
                }
            }
            window.addEventListener('keydown', keydown)
            return () => {
                window.removeEventListener('keydown', keydown)
            }
        })

        const previousSegmentEnabled = segmentIndex > 0
        const nextSegmentEnabled = segmentIndex < segments.length - 1
        const appendSegmentEnabled =
            isNotPlayingNorRecording && iAmTranslatorForPassage(passage) && !isInReview && passageVideo?.isAppendable()

        return (
            <div className="segment-selector">
                <PreviousSegmentButton
                    enabled={previousSegmentEnabled}
                    tooltip={t('Go to previous segment.')}
                    onClick={() => gotoSegment(segments[segmentIndex - 1])}
                    dir={dir}
                />
                <span className="segment-selector-text">{`${t('Segment')} ${segmentIndex + 1}`}</span>
                <NextSegmentButton
                    enabled={nextSegmentEnabled}
                    tooltip={t('Go to next segment.')}
                    onClick={() => gotoSegment(segments[segmentIndex + 1])}
                    dir={dir}
                />
                {segmentIndex === segments.length - 1 && (
                    <GenericIconButton
                        iconName={dir === 'rtl' ? 'fa-circle-left' : 'fa-circle-right'}
                        className="sl-segment-toolbar-button append-segment-button"
                        iconType="far"
                        tooltip={t('appendNewSegment')}
                        enabled={appendSegmentEnabled}
                        onClick={() => {
                            record(RecordingType.APPENDED_SEGMENT)
                        }}
                    />
                )}
            </div>
        )
    }
)

interface ISegmentSelector {
    rt: Root
    segment: PassageSegment
    record: (recordingType: RecordingType) => void
    isNotPlayingNorRecording: boolean
    isInReview: boolean
}

const SegmentSelector = observer(({ rt, segment, record, isNotPlayingNorRecording, isInReview }: ISegmentSelector) => {
    const { passageVideo, passage } = rt

    if (!segment || !passage || !passageVideo) {
        return null
    }

    const segments = passageVideo.visibleSegments(passage)
    const index = segments.findIndex((s) => s._id === segment._id)
    if (index === -1) {
        return null
    }

    return (
        <SegmentButtons
            rt={rt}
            segmentIndex={index}
            segments={segments}
            record={record}
            isNotPlayingNorRecording={isNotPlayingNorRecording}
            isInReview={isInReview}
        />
    )
})

export default SegmentSelector
