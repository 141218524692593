import { useMemo, useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { pericopesInRef, useBiblePericopes, BiblePericopes } from '../../hooks/useBiblePericopes'
import { RefRange, refToBookId } from '../../resources/RefRange'
import { MultiSelect, Option } from '../projectSettings/projectResources/MultiSelect'
import { LoadingIcon } from '../utils/Icons'
import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'

const getPericopes = ({
    biblePericopes,
    selectedPericopeType,
    refBookId,
    references,
    passageName
}: {
    biblePericopes: BiblePericopes | undefined
    selectedPericopeType: Option<string>[]
    refBookId: string
    references: RefRange[]
    passageName: string
}) => {
    if (biblePericopes && selectedPericopeType.length && biblePericopes[selectedPericopeType[0].value][refBookId]) {
        return pericopesInRef({
            pericopes: biblePericopes[selectedPericopeType[0].value][refBookId],
            startRef: references[0].startRef,
            endRef: references[0].endRef
        }).map(({ title, startRef, endRef }) => {
            const label = passageName ? `${passageName} ${title}` : title
            const value = `${startRef}-${endRef}`
            return { label, value }
        })
    }
    return []
}

export const MultipleAddFields = ({
    references,
    passageName,
    selectedPericopes,
    setSelectedPericopes,
    selectedPericopeType,
    setSelectedPericopeType
}: {
    references: RefRange[]
    passageName: string
    selectedPericopes: Option<string>[]
    setSelectedPericopes: (selected: Option<string>[]) => void
    selectedPericopeType: Option<string>[]
    setSelectedPericopeType: (selected: Option<string>[]) => void
}) => {
    const { t } = useTranslation()
    const [srvPericopes, setSrvPericopes] = useState<Option<string>[]>([])
    const { biblePericopes, pericopeTypes, isLoading } = useBiblePericopes()

    const refBookId = useMemo(() => refToBookId(references[0]?.startRef || ''), [references])

    const memoizedPericopes = useMemo(() => {
        return getPericopes({
            biblePericopes,
            selectedPericopeType,
            refBookId,
            references,
            passageName
        })
    }, [biblePericopes, selectedPericopeType, references, passageName, refBookId])

    useEffect(() => {
        if (biblePericopes?.srvPericopes[refBookId]) {
            setSrvPericopes(
                getPericopes({
                    biblePericopes,
                    selectedPericopeType: [{ label: 'passage per Study Guide', value: 'srvPericopes' }],
                    refBookId,
                    references,
                    passageName
                })
            )
        } else {
            setSrvPericopes([])
        }
    }, [biblePericopes, references, passageName, refBookId])

    useEffect(() => {
        if (
            references.length &&
            srvPericopes.length === 0 &&
            selectedPericopeType.some((pericopeType) => pericopeType.value === 'srvPericopes')
        ) {
            setSelectedPericopeType([])
        }
    }, [references, setSelectedPericopeType, srvPericopes, selectedPericopeType])

    const setNewPericopeType = (pericopeType: Option<string>[]) => {
        setSelectedPericopeType(pericopeType)
        setSelectedPericopes([])
    }

    if (isLoading) {
        return <LoadingIcon className="" />
    }

    const passagePericopeTypeOptions = () =>
        pericopeTypes.map(({ id, title }) => ({
            label: title,
            value: id,
            disabled: !references.length || (srvPericopes.length === 0 && id === 'srvPericopes')
        }))

    return (
        <>
            <LabeledFieldWithIcon
                iconName="fa-paragraph"
                title={t('passagePericopeType')}
                field={
                    <MultiSelect
                        singleSelect
                        options={passagePericopeTypeOptions()}
                        selected={selectedPericopeType}
                        setSelected={setNewPericopeType}
                    />
                }
            />

            <LabeledFieldWithIcon
                iconName="fa-plus-square"
                title={t('passagesToAdd')}
                field={
                    <MultiSelect
                        options={memoizedPericopes}
                        selected={selectedPericopes}
                        setSelected={setSelectedPericopes}
                        disabled={selectedPericopeType.length === 0}
                    />
                }
            />
        </>
    )
}
