import { TFunction } from 'react-i18next'

import { displayError } from '../Errors'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('avtt:OralTranscrier')

declare const webkitSpeechRecognition: any

export class OralTranscriber {
    recognition: any

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTranscriptionUpdate: (transcription: string) => void

    t: TFunction

    // These are subset of likely AVTT users. The complete list is derived from https://cloud.google.com/speech-to-text/docs/languages.
    static supportedLanguages = [
        { value: '', label: '' },
        { value: 'en-AU', label: 'English (Australia)' },
        { value: 'en-CA', label: 'English (Canada)' },
        { value: 'en-GH', label: 'English (Ghana)' },
        { value: 'en-HK', label: 'English (Hong Kong)' },
        { value: 'en-IN', label: 'English (India)' },
        { value: 'en-IE', label: 'English (Ireland)' },
        { value: 'en-KE', label: 'English (Kenya)' },
        { value: 'en-NZ', label: 'English (New Zealand)' },
        { value: 'en-NI', label: 'English (Nigeria)' },
        { value: 'en-PK', label: 'English (Pakistan)' },
        { value: 'en-PH', label: 'English (Philippines)' },
        { value: 'en-SG', label: 'English (Singapore)' },
        { value: 'en-ZA', label: 'English (South Africa)' },
        { value: 'en-TA', label: 'English (Tanzania)' },
        { value: 'en-GB', label: 'English (United Kingdom)' },
        { value: 'en-US', label: 'English (United States)' },

        { value: 'es-AR', label: 'Español (Argentina)' },
        { value: 'es-BO', label: 'Español (Bolivia)' },
        { value: 'es-CL', label: 'Español (Chile)' },
        { value: 'es-CO', label: 'Español (Colombia)' },
        { value: 'es-CR', label: 'Español (Costa Rica)' },
        { value: 'es-EC', label: 'Español (Ecuador)' },
        { value: 'es-SV', label: 'Español (El Salvador)' },
        { value: 'es-ES', label: 'Español (España)' },
        { value: 'es-US', label: 'Español (Estados Unidos)' },
        { value: 'es-GT', label: 'Español (Guatemala)' },
        { value: 'es-HN', label: 'Español (Honduras)' },
        { value: 'es-MX', label: 'Español (México)' },
        { value: 'es-NI', label: 'Español (Nicaragua)' },
        { value: 'es-PA', label: 'Español (Panamá)' },
        { value: 'es-PY', label: 'Español (Paraguay)' },
        { value: 'es-PE', label: 'Español (Perú)' },
        { value: 'es-PR', label: 'Español (Puerto Rico)' },
        { value: 'es-DO', label: 'Español (República Dominicana)' },
        { value: 'es-UY', label: 'Español (Uruguay)' },
        { value: 'es-VE', label: 'Español (Venezuela)' },

        { value: 'fr-CA', label: 'Français (Canada)' },
        { value: 'fr-FR', label: 'Français (France)' },

        { value: 'sw-KE', label: 'Kiswahili (Kenya)' },
        { value: 'sw-TZ', label: 'Kiswahili (Tanzania)' },

        { value: 'pt-BR', label: 'Português (Brasil)' },
        { value: 'pt-PT', label: 'Português (Portugal)' },

        { value: 'ru-RU', label: 'Русский (Россия)' },

        { value: 'ka-ge', label: 'ქართული (საქართველო)' },

        { value: 'ar-eg', label: 'العربية (مصر)' },
        { value: 'ar-sa', label: 'العربية (السعودية)' }
    ]

    constructor(onTranscriptionUpdate: (transcription: string) => void, languageCode: string, t: TFunction) {
        if ('webkitSpeechRecognition' in window) {
            // eslint-disable-next-line new-cap
            this.recognition = new webkitSpeechRecognition()
            this.recognition.interimResults = true
            this.recognition.continuous = true
            this.recognition.lang = languageCode
            this.onTranscriptionUpdate = onTranscriptionUpdate
        } else {
            this.recognition = undefined
            this.onTranscriptionUpdate = () => {}
            log('Speech recognition not supported')
        }

        this.t = t
    }

    start() {
        if (!this.recognition) {
            return
        }

        let interimTranscript = ''
        let finalTranscript = ''

        this.recognition.onstart = () => {
            log('Auto transcriber recognition started')
        }

        this.recognition.onerror = () => {
            this.onTranscriptionUpdate('')
            displayError(this.t('An error occurred while transcribing'))
            this.recognition?.stop()
        }

        this.recognition.onend = () => {
            log('Auto transcriber recognition stopped')
        }

        this.recognition.onresult = (event: any) => {
            for (let i = event.resultIndex; i < event.results.length; i++) {
                if (event.results[i].isFinal) {
                    finalTranscript += event.results[i][0].transcript
                    this.onTranscriptionUpdate(finalTranscript)
                } else {
                    interimTranscript = finalTranscript + event.results[i][0].transcript
                    this.onTranscriptionUpdate(interimTranscript)
                }
            }
        }

        this.recognition.start()
    }

    stop() {
        this.recognition?.stop()
    }
}
