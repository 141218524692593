import { useState } from 'react'

import { Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { MultipleAddFields } from './MultipleAddFields'
import { RecordingTypeChooser } from './RecordingTypeChooser'
import { Passage, PassageContentTypes } from '../../models3/Passage'
import { Root } from '../../models3/Root'
import { RefRange } from '../../resources/RefRange'
import { RecordingMediaType } from '../../types'
import { uploadRecording } from '../filePickers/BaseRecordingFilePicker'
import { ProgressModalBodyAndFooter } from '../modals/Modals'
import { MultiSelect, Option } from '../projectSettings/projectResources/MultiSelect'
import { ReferenceInput } from '../referenceInput/ReferenceInput'
import { CancelEditSegmentButton, OkEditSegmentButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'
import { MINIMUM_VIABLE_OPUS_BITRATE, createAudioTemplate } from '../utils/Opus'
import { Switch } from '../utils/Switch'
import { SimpleTextInput } from '../utils/TextInput'

const DifficultyInput = ({
    difficulty,
    setDifficulty
}: {
    difficulty: number
    setDifficulty: (difficulty: number) => void
}) => {
    const [value, setValue] = useState(difficulty.toString())

    return (
        <input
            className="difficulty-input"
            required
            type="number"
            value={value}
            min={0}
            step={0.1}
            onChange={(e) => {
                setDifficulty(parseFloat(e.target.value))
                setValue(e.target.value)
            }}
        />
    )
}

const PassageEditor = ({
    rt,
    passage,
    setEditing
}: {
    rt: Root
    passage?: Passage
    setEditing: (editing: boolean) => void
}) => {
    const { t } = useTranslation()

    const getInitialRecordingType = () => {
        if (passage?.latestVideo) {
            return passage?.latestVideo.isAudioOnly() ? RecordingMediaType.AUDIO : RecordingMediaType.VIDEO
        }

        return passage?.recordingMediaType ?? RecordingMediaType.AUDIO
    }

    const [passageName, setPassageName] = useState(passage?.name ?? '')
    const [nameError, setNameError] = useState('')
    const [references, setReferences] = useState(passage?.references ?? [])
    const [referencesError, setReferencesError] = useState(false)
    const [passageRecordingType, setPassageRecordingType] = useState(getInitialRecordingType())
    const [difficulty, setDifficulty] = useState(passage?.difficulty ?? 1.0)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const passageContentTypes = Object.keys(PassageContentTypes).map((type) => ({ label: t(type), value: type }))
    const initialContentType = passage
        ? [{ label: t(passage.contentType), value: passage.contentType }]
        : passageContentTypes.slice(0, 1)

    const [contentType, setContentType] = useState<Option<string>[]>(initialContentType)

    const [isMultipleAdd, setIsMultipleAdd] = useState(false)
    const [selectedPericopes, setSelectedPericopes] = useState<Option<string>[]>([])
    const [selectedPericopeType, setSelectedPericopeType] = useState<Option<string>[]>([])
    const [shouldAddVerseReferences, setShouldAddVerseReferences] = useState(false)
    const [showResultsPage, setShowResultsPage] = useState(false)
    const [isAddingPassages, setIsAddingPassages] = useState(false)

    const portion = rt.portion
    const isAdd = !passage

    if (!portion) {
        const error = t('portionNotFound')
        console.error(error)
        displayError(error)
        return null
    }

    const setNewReferences = (newReferences: RefRange[]) => {
        setReferences(newReferences)
        if (isMultipleAdd) {
            setSelectedPericopes([])
        }
    }

    const validatePassageName = (newValue: string) => {
        if (isMultipleAdd || passage?.name === newValue) return ''

        return portion.checkNewPassageName(newValue)
    }

    const areValidInputs = () => {
        if (contentType.length === 0 || isNaN(difficulty) || referencesError) {
            return false
        }

        if (isMultipleAdd) {
            return selectedPericopes.length > 0 && selectedPericopeType.length > 0
        }

        return validatePassageName(passageName) === ''
    }

    const addVerseMarkers = async (newPassage: Passage) => {
        const verses = RefRange.getAllVersesInRefRanges(newPassage.references, rt.project.versification)
        if (verses.length === 0) {
            return
        }

        newPassage.setCompressionProgressMessage(t('Starting compression...'))

        const secondsPerVerse = 1
        const templateDurationSeconds = verses.length * secondsPerVerse
        const file = await createAudioTemplate(templateDurationSeconds)
        const savedVideo = await uploadRecording({
            file,
            rt,
            passage: newPassage,
            t,
            audioCompressionOptions: { bitrate: MINIMUM_VIABLE_OPUS_BITRATE }
        })

        if (!savedVideo) {
            newPassage.setCompressionProgressMessage('')
            return
        }

        newPassage.setCompressionProgressMessage(t('addingVerseReferencesProgress'))

        // We must add segments sequentially, because adding a segment also updates the
        // positions of the segments around it
        for (let i = 0; i < verses.length; i++) {
            await savedVideo.addSegment({ position: i })
            await savedVideo.addReference([new RefRange(verses[i], verses[i])], i)
        }
        newPassage.setCompressionProgressMessage('')
    }

    const addPassage = async ({
        name,
        passageReferences,
        checkDuplicate
    }: {
        name: string
        passageReferences: RefRange[]
        checkDuplicate?: boolean
    }) => {
        const savedContentType = contentType[0].value as PassageContentTypes
        const newPassage = portion.createPassage(name, checkDuplicate)
        newPassage.references = passageReferences
        newPassage.difficulty = difficulty
        newPassage.contentType = savedContentType
        newPassage.recordingMediaType = passageRecordingType
        const savedPassage = await portion.addPassageFromExisting(newPassage)
        if (shouldAddVerseReferences && passageRecordingType === RecordingMediaType.AUDIO) {
            await addVerseMarkers(savedPassage)
        }
        return savedPassage
    }

    const save = async () => {
        const savedContentType = contentType[0].value as PassageContentTypes

        try {
            if (isMultipleAdd) {
                for (const { value, label } of selectedPericopes) {
                    const [startRef, endRef] = value.split('-')
                    await addPassage({
                        name: label,
                        passageReferences: [new RefRange(startRef, endRef)],
                        checkDuplicate: false
                    })
                }
            } else if (isAdd) {
                const savedPassage = await addPassage({ name: passageName, passageReferences: references })
                await rt.setPassage(savedPassage)
            } else {
                await passage.setName(passageName)
                await passage.setReferences(references)
                await passage.setDifficulty(difficulty)
                await passage.setContentType(savedContentType)
            }

            rt.setDbsRefs(portion)
        } catch (error) {
            console.error(error)
            displayError(error)
        }
    }

    return (
        <Modal show onHide={() => setEditing(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title className="passage-editor">
                    <div className="biblical-term-marker-editor-header">{t('Passage')}</div>
                </Modal.Title>
                {!showResultsPage && (
                    <RecordingTypeChooser
                        recordingTypes={[RecordingMediaType.AUDIO, RecordingMediaType.VIDEO]}
                        selected={passageRecordingType}
                        setSelected={(recordingType) => {
                            setPassageRecordingType(recordingType)
                            setShouldAddVerseReferences(false)
                        }}
                        enabled={isAdd}
                    />
                )}
            </Modal.Header>
            {showResultsPage && (
                <ProgressModalBodyAndFooter
                    closeModal={() => setEditing(false)}
                    error=""
                    loading={isAddingPassages}
                    loadingMessage={t('addingPassagesProgress')}
                    successBody={<div>{t('addingPassagesSuccess')}</div>}
                />
            )}
            {!showResultsPage && (
                <>
                    <Modal.Body>
                        {isAdd && (
                            <div className="passages-modal-toggle">
                                {t('addMultiplePassages')}
                                <Switch
                                    value={isMultipleAdd}
                                    setValue={(newValue) => {
                                        setIsMultipleAdd(newValue)
                                        setNameError('')
                                        setPassageName('')
                                    }}
                                    tooltip={isMultipleAdd ? t('addMultiplePassages') : t('addSinglePassage')}
                                />
                            </div>
                        )}
                        {isAdd && (
                            <div style={{ display: 'flex' }}>
                                <Form.Check
                                    type="checkbox"
                                    label={t('addVerseReferences')}
                                    id="add-verse-references"
                                    className="add-verse-reference-label"
                                    checked={shouldAddVerseReferences}
                                    disabled={passageRecordingType !== RecordingMediaType.AUDIO}
                                    reverse
                                    onChange={() => setShouldAddVerseReferences(!shouldAddVerseReferences)}
                                />
                            </div>
                        )}
                        <LabeledFieldWithIcon
                            iconName="fa-tag"
                            title={isAdd && isMultipleAdd ? t('titlePrefixOptional') : t('Title')}
                            field={
                                <SimpleTextInput
                                    autoFocus
                                    value={passageName}
                                    setValue={(value: string) => {
                                        setNameError(validatePassageName(value))
                                        setPassageName(value)
                                        if (isMultipleAdd) {
                                            setSelectedPericopes([])
                                        }

                                        try {
                                            const parsedNewReferences = rt.parseReferences(value.trim(), isMultipleAdd)
                                            if (parsedNewReferences.length > 0) {
                                                // Continue to show old references until the user types a valid reference
                                                setNewReferences(parsedNewReferences)
                                                setReferencesError(false)
                                            }
                                        } catch (error) {
                                            // Ignore, since we just want to know whether the string can be parsed as a reference
                                        }
                                    }}
                                    errorMessage={nameError}
                                />
                            }
                        />
                        <LabeledFieldWithIcon
                            iconName="fa-book"
                            title={t('References')}
                            field={
                                <ReferenceInput
                                    refInput={rt}
                                    refs={references}
                                    setRefs={setNewReferences}
                                    errored={referencesError}
                                    setErrored={setReferencesError}
                                    showSuggestions
                                    autoComplete
                                />
                            }
                        />
                        {isMultipleAdd && (
                            <MultipleAddFields
                                passageName={passageName}
                                references={references}
                                selectedPericopes={selectedPericopes}
                                setSelectedPericopes={setSelectedPericopes}
                                selectedPericopeType={selectedPericopeType}
                                setSelectedPericopeType={setSelectedPericopeType}
                            />
                        )}
                        <LabeledFieldWithIcon
                            iconName="fa-certificate"
                            title={t('passageType')}
                            field={
                                <MultiSelect
                                    singleSelect
                                    options={passageContentTypes}
                                    selected={contentType}
                                    setSelected={setContentType}
                                />
                            }
                        />
                        <LabeledFieldWithIcon
                            iconName="fa-cog"
                            title={t('difficulty')}
                            field={<DifficultyInput {...{ difficulty, setDifficulty }} />}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="button-row">
                            <OkEditSegmentButton
                                enabled={areValidInputs()}
                                onClick={async () => {
                                    if (isAdd) {
                                        setShowResultsPage(true)
                                        setIsAddingPassages(true)
                                        await save()
                                        setIsAddingPassages(false)
                                        return
                                    }
                                    await save()
                                    setEditing(false)
                                }}
                            />
                            <CancelEditSegmentButton enabled onClick={() => setEditing(false)} />
                        </div>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    )
}

export default PassageEditor
